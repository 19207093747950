import './App.css';
import Navbar from './Components/Navbar/Navbar';
import CustomButton from './Components/Button/Button';
import Fireworks from './Components/Design/Fireworks';

function App() {
  return (
    <div className="App">
      <Navbar />
      
      <div className="container">
      <Fireworks />
        <h1>Transform your online presence with expert web design...</h1>
        <h4>
        Ready to elevate your brand? I create visually stunning, user-friendly websites tailored to your needs. From design to functionality, I’ll ensure your site stands out and drives success. Click below for a FREE consultation to start bringing your vision to life!
        </h4>
        <CustomButton text="Free Consultation" onClick={() => alert('Button Clicked!')} />
      </div>
    </div>
  );
}

export default App;
