import React, { useState } from "react";
import "./Navbar.css"; 
import { ReactComponent as Logo } from '../../images/mwd.svg';
import { ReactComponent as Hamburger } from '../../images/hamburger.svg';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
      setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="logo">
        <Logo width="250px" height="250px" />
        </div>
      <ul className={`nav-list ${isOpen ? 'open' : ''}`}>
        <li className="nav-item"><a href="#home">Home</a></li>
        <li className="nav-item"><a href="#about">About</a></li>
        <li className="nav-item"><a href="#services">Services</a></li>
        <li className="nav-item"><a href="#contact">Contact</a></li>
      </ul>
      <div className="hamburger" onClick={toggleMenu}>
        {console.log(Logo)}
        <Hamburger width="40px" height="40px" />
      </div>
    </nav>
  );
};

export default Navbar;