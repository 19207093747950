import React, { useState, useEffect } from 'react';
import './Fireworks.css';

function Fireworks() {
    const [fireworks, setFireworks] = useState([]);

    const handleMouseMove = (e) => {
        const newFirework = {
            id: Date.now(),
            x: e.clientX,
            y: e.clientY
        };
        setFireworks((prevFireworks) => [...prevFireworks, newFirework]);

        // Remove the firework after a short delay
        setTimeout(() => {
            setFireworks((prevFireworks) =>
                prevFireworks.filter((firework) => firework.id !== newFirework.id)
            );
        }, 1000); // Firework lasts for 1 second
    };

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <div className="fireworks-container">
            {fireworks.map((firework) => (
                <div
                    key={firework.id}
                    className="firework"
                    style={{ left: firework.x, top: firework.y }}
                />
            ))}
        </div>
    );
}

export default Fireworks;
